const mapUser = (data) => {
  let user = {};
  switch (true) {
    case data.partner !== undefined:
      user = data.partner;
      break;
    case data.client !== undefined:
      user = data.client;
      break;
    case data.association !== undefined:
      user = data.association;
      break;
    case data.carrier !== undefined:
      user = data.carrier;
      break;
    default:
      break;
  }
  return user;
};

export default mapUser;
