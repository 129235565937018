import React, {
  useState, createContext, useContext, useEffect,
} from 'react';
import client from '../httpServices/client/index';
import mapUser from '../helpers/mapUser';
import { authService } from '../httpServices/services';

export const UserContext = createContext([]);

export const useUser = () => useContext(UserContext);

export const UserWrapper = ({ children }) => {
  const [user, setUser] = useState(null);
  const [working, setWorking] = useState(true);

  const refreshToken = () => {
    authService.refreshToken()
      .then((res) => {
        if (res.data.jwt_token !== undefined
          && res.data.role !== undefined
           && !Number.isNaN(res.data.jwt_token_expiry)
            && (res.data.association !== undefined
              || res.data.partner !== undefined
              || res.data.client !== undefined
              || res.data.carrier !== undefined)) {
          client.defaults.headers.common['jwt-token'] = `${res.data.jwt_token}`;

          if (res.data.jwt_token_expiry !== undefined && res.data.jwt_token_expiry !== 0) {
            setTimeout(() => {
              // refreshToken()
            }, (res.data.jwt_token_expiry));
          }
          const newUser = mapUser(res.data);
          setUser({
            role: res.data.role,
            mustChangePassword: res.data.mustChangePassword,
            user: newUser,
          });
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setWorking(false);
      });
  };

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {working ? null : children}
    </UserContext.Provider>
  );
};
