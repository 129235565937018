import client from '../client/index';

const newPartner = (data) => client.post('/association/create_partner', data);

const addLicences = (data) => client.post('/association/add_licences', data);

const getPartners = (startIndex, stopIndex, filter) => client.get(`/association/partners?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const verifyLicenceNumber = (licenceNumber) => client.get(`/association/verify_licence_number/${licenceNumber}`);

const editPartnerEmail = (data) => client.put('/association/update_partner_email', data);

const asocciationService = {
  newPartner,
  addLicences,
  getPartners,
  verifyLicenceNumber,
  editPartnerEmail,
};

export default asocciationService;
