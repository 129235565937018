import axios from 'axios';

import getHost from "../../helpers/getHost";
const host = getHost();
let CORS_allow_origin = "";
if (host === 'ANAPAT') {
  CORS_allow_origin = `${process.env.REACT_APP_ANAPAT_URL_HTTP}`
} else if (host === 'AECE') {
  CORS_allow_origin = `${process.env.REACT_APP_AECE_URL_HTTP}`
}
const url = process.env.REACT_APP_API_URL || '';
axios.defaults.withCredentials = true;
const axiosClient = axios.create({
  headers: {
    'Access-Control-Allow-Origin': CORS_allow_origin,
  },
  withCredentials: true,
  baseURL: url,
});

export default axiosClient;
