import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import paths from './paths';
import { useUser } from '../../store';
import CompletePartnerProfile from '../../components/pages/CompletePartnerProfile/CompletePartnerProfile';
import ChangePasswordViewPaper from '../../components/pages/ChangePasswordViewPaper/ChangePasswordViewPaper';
import loggedIn from '../../services/loggedIn';

const PartnerRoute = (props) => {
  const { user } = useUser();

  if (loggedIn(user) && user.role === 'PARTNER') {
    if (user.mustChangePassword === true) {
      return (
        <ChangePasswordViewPaper />
      );
    } 
    if (user.user?.termsAndConditions === false) {
      return (
        <CompletePartnerProfile />
      );
    }
    return <Route path={props.path} exact={props.exact} component={props.component} />;
  }
  return <Redirect to={paths.login} />;
};

export default PartnerRoute;
