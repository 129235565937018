import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Routes from './config/router/routes';
import { UserWrapper } from './store';
import { themeAECE } from './styles/themeAECE';
import { themeANAPAT } from './styles/themeANAPAT';
import { commonColors } from './styles/index';
import TermsAndConditionsWide from './components/components/TermsAndConditionsWide/TermsAndConditionsWide';
import getHost from './helpers/getHost';

const filecookiesaece = require('./static/V2_Política_Protección_DatosAPP_AECE.pdf');
const filecookiesanapat = require('./static/V2_Política_Protección_DatosAPP_anapat.pdf');

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: commonColors.background.hex,
    }
  },
  cookieBar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const App = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const host = getHost();
  let theme = themeANAPAT;
  let fileCookies = null;
  if (host === 'ANAPAT') {
    theme = themeANAPAT;
    fileCookies = filecookiesanapat;
  } else if (host === 'AECE') {
    theme = themeAECE;
    fileCookies = filecookiesaece;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box height="100vh" display="flex" flexDirection="column" className={classes.root}>
        <Box flex={1} overflow="auto">
          <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <UserWrapper>
              <Routes />
              <CookieConsent
                location="bottom"
                buttonText={t('accept')}
                style={{ backgroundColor: '#000' }}
                buttonStyle={{ backgroundColor: '#fff', padding: '15px' }}
                expires={150}
              >
                {t('cookiesMnsg')}
                {' '}
                <TermsAndConditionsWide anchorText={t('cookiesDoc')} file={fileCookies} />
              </CookieConsent>
            </UserWrapper>
          </SnackbarProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
