import * as yup from 'yup';

const changePasswordFirstTimeSchema = (props) => {
  const { t } = props;
  return yup.object().shape({
    password: yup.string()
      .min(6, `${t('minLength')} ${6}`)
      .max(25, `${t('maxLength')} ${25}`),
    repassword: yup.string()
      .min(6, `${t('minLength')} ${6}`)
      .max(25, `${t('maxLength')} ${25}`)
      .required(t('passRequired'))
      .oneOf([yup.ref('password'), null], t('passwordMatch')),
  });
};
export default changePasswordFirstTimeSchema;
