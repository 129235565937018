/* eslint-disable jsx-a11y/anchor-is-valid */
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  colors: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingTop: '10px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}));

const TermsAndConditionsWide = (props) => {
  const { t } = useTranslation();
  const { anchorText, file } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  return (
    <>
      <Link color="primary" variant="body1" className={classes.link} onClick={handleClickOpen}>
        {anchorText}
      </Link>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogContent>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={
              [
                { uri: file },
              ]
            }
            config={{
              header: {
                disableFileName: false,
                disableHeader: true,
                retainURLParams: false,
              },
            }}
            theme={{
              primary: theme.palette.primary.main,
              secondary: '#ffffff',
              tertiary: theme.palette.secondary.main,
              text_primary: '#ffffff',
              text_secondary: '#ffffff',
              text_tertiary: '#ffffff',
              disableThemeScrollbar: false,

            }}
            style={{ maxHeight: '700px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsAndConditionsWide;
