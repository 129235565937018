import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ANAPAT_ENTranslations from '../consts/translations/ANAPAT_ENTranslations.json';
import AECE_ENTranslations from '../consts/translations/AECE_ENTranslations.json';
import ANAPAT_ESTranslations from '../consts/translations/ANAPAT_ESTranslations.json';
import AECE_ESTranslations from '../consts/translations/AECE_ESTranslations.json';
import getHost from "../helpers/getHost";

let ESTranslation = '';
let ENTranslation = '';
const host = getHost();
if (host === 'ANAPAT') {
    ESTranslation = ANAPAT_ESTranslations;
    ENTranslation = ANAPAT_ENTranslations;
} else if (host === 'AECE') {
    ESTranslation = AECE_ESTranslations;
    ENTranslation = AECE_ENTranslations;
}

// the translations
const resources = {
  es: {
    translation: ESTranslation,
  },
  en: {
    translation: ENTranslation,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'es', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
