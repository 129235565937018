import client from '../client/index';

const completeProfile = (data) => client.put('/partner/complete_profile', data);

const uploadPartnerImgProfile = (data) => client.put('/partner/upload_partner_img_profile', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const aceptTerms = (data) => client.put('/partner/acept_terms', { terms: data });

const newContract = (data) => client.post('/partner/new_contract', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const getTrazability = (contractId) => client.get(`/partner/trazability/${contractId}`);

const getContracts = (startIndex, stopIndex, filter) => client.get(`/partner/contracts?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const updatePartnerProfile = (data) => client.put('/partner/update_profile', data);

const resendContractEmail = (contractId) => client.get(`/partner/resend_contract_email/${contractId}`);

const updateContractClient = (data) => client.put('/partner/update_contract_client', data);

const deleteContract = (contractId) => client.delete(`/partner/delete_contract/${contractId}`);

const newMachinery = (data) => client.post('/partner/new_machinery', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const newBulkMachinery = (data) => client.post('/partner/bulk_new_machinery', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const getMachinery = (startIndex, stopIndex, filter) => client.get(`/partner/machinery?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const deleteMachineryDocument = (chassisNumber, documentId) => client.delete(`/partner/delete_document/${chassisNumber}/${documentId}`);

const deleteMachinery = (chassisNumber) => client.delete(`/partner/delete_machine/${chassisNumber}`);

const newMachineryDocument = (chassisNumber, data) => client.post(`/partner/register_machinery_new_document/${chassisNumber}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

const getMachineryDocumentById = (chassisNumber, document_id) => client.get(`/partner/donwload_machinery_document/${chassisNumber}/${document_id}`);

const deleteAllMachineries = () => client.delete(`/partner/delete_all_machineries`);

const getMachineriesData = (format) => client.get(`/partner/getMachineryData/${format}`);

const isMachineryListEmpty = () => client.get(`/partner/isMachineryListEmpty`);

const partnerService = {
  completeProfile,
  newContract,
  getTrazability,
  getContracts,
  aceptTerms,
  updatePartnerProfile,
  uploadPartnerImgProfile,
  resendContractEmail,
  updateContractClient,
  deleteContract,
  newMachinery,
  newBulkMachinery,
  getMachinery,
  deleteMachineryDocument,
  deleteMachinery,
  newMachineryDocument,
  getMachineryDocumentById,
  deleteAllMachineries,
  getMachineriesData,
  isMachineryListEmpty
};

export default partnerService;
