import client from '../client/index';

const completeContract = (data) => client.post('/client/completeContract', data);

const sendDocumentation = (data) => client.post('/client/send_documentation_employees', data);

const getContracts = (startIndex, stopIndex, filter) => client.get(`/client/contracts?start=${startIndex}&end=${stopIndex}&filter=${filter}`);

const updateClientProfile = (data) => client.put('/user/updateClientProfile', data);

const clientAcceptTerms = (data) => client.post('/client/accept_terms', data);

const hasPendingContract = () => client.get('/client/has_pending_contracts');

const clientService = {
  completeContract,
  sendDocumentation,
  getContracts,
  updateClientProfile,
  hasPendingContract,
  clientAcceptTerms,
};

export default clientService;
