import styled from 'styled-components';
import { InputLabel, FormControl, makeStyles } from '@material-ui/core';

// main: {
//   rgba: "rgba(255, 45, 37, 1)",
//   hex: "#e53f2e",
//   // hex: "#ff2d25",
// },
export const AECEColors = {
  primary: {
    light: {
      rgba: "rgba(255, 106, 81, 1)",
      hex: "#ff6a51"
    },
    main: {
      rgba: "rgba(255, 45, 37, 1)",
      hex: "#e53f2e",
    },
    dark: {
      rgba: "rgba(195, 0, 0, 1)",
      hex: "#c30000"
    },
    text: {
      rgba: "rgba(255, 255, 255, 1)",
      hex: "#ffffff",
    }
  },
  secondary: {
    light: {
      rgba: "rgba(44, 44, 44, 1)",
      hex: "#2c2c2c"
    },
    main: {
      rgba: "rgba(0, 0, 0, 1)",
      rgbaDegradation: "rgba(0, 0, 0, 0.2)",
      hex: "#e53f2e",
      // hex: "#ff6a51",
    },
    dark: {
      rgba: "rgba(0, 0, 0, 1)",
      hex: "#000000",
    },
    text: {
      rgba: "rgba(255, 255, 255, 1)",
      hex: "#ffffff",
    }
  },
  error: {
    light: {
      hex: "#e57373"
    },
    main: {
      hex: "#f44336"
    },
    dark: {
      hex: "#d32f2f"
    },
    contrastText: {
      hex: "#fff"
    }
  },
  background: {
    hex: "#fafafa"
  }
}

export const ANAPATColors = {
  primary: {
    light: {
      rgba: 'rgba(67, 104, 162, 1)',
      hex: '#4368a2',
    },
    main: {
      rgba: 'rgba(0, 62, 115, 1)',
      hex: '#003e73',
    },
    dark: {
      rgba: 'rgba(0, 25, 71, 1)',
      hex: '#001947',
    },
    text: {
      rgba: 'rgba(255, 255, 255, 1)',
      hex: '#ffffff',
    },
  },
  secondary: {
    light: {
      rgba: 'rgba(158, 192, 215, 1)',
      hex: '#9ec0d7',
    },
    main: {
      rgba: 'rgba(110, 144, 166, 1)',
      rgbaDegradation: 'rgba(110, 144, 166, 0.2)',
      hex: '#6E90A6',
    },
    dark: {
      rgba: 'rgba(64, 99, 119, 1)',
      hex: '#406377',
    },
    text: {
      rgba: 'rgba(255, 255, 255, 1)',
      hex: '#ffffff',
    },
  },
  error: {
    light: {
      hex: '#e57373',
    },
    main: {
      hex: '#f44336',
    },
    dark: {
      hex: '#d32f2f',
    },
    contrastText: {
      hex: '#fff',
    },
  },
};

export const commonColors = {
  background: {
    hex: '#fafafa',
  },
  pendingDocument: {
    // hex: '#acaaf340'
    hex: '#ff654226',
    // hex: '#ff99005e'
  },
};

export const useStylesPaper = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      // if you want to set the md size value
      padding: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      // if you want to set the md size value
      padding: '15px',
    },
  },
  partner: {
    [theme.breakpoints.up('sm')]: {
      // if you want to set the md size value
      padding: '50px 50px 50px 50px',
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0"
    },
    [theme.breakpoints.down('sm')]: {
      // if you want to set the md size value
      padding: '15px',
    },
  }
}));

export const useStylesCommon = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      // if you want to set the md size value
      // display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      // if you want to set the md size value
      display: 'none',
    },
  },
}));

export const useTextBox = makeStyles(() => ({
  root: {
    width: '70%',
  },
  almostFull: {
    width: '90%',
  },
  full: {
    width: '100%',
  },
}));

export const StyledInputLabel = styled(InputLabel)`
  && {
    .req-label {
      color: #f44336;
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
    display: block;
    position: relative;
  }
`;

export const StyledAutoSelectInputLabel = styled(InputLabel)`
  && {
    position: relative;
    .req-label {
      color: #f44336;
    }
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }
`;
