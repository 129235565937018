import { Container, Paper } from '@material-ui/core';
import React from 'react';
import { useStylesPaper } from '../../../styles';
import ChangePasswordFirstTime from '../../components/ChangePasswordFirstTime/ChangePasswordFirstTime';

const ChangePasswordViewPaper = () => {
  const paperClasses = useStylesPaper();

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} xs={12} className={paperClasses.root}>
        <ChangePasswordFirstTime />
      </Paper>
    </Container>
  );
};

export default ChangePasswordViewPaper;
