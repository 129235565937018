import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from './paths';
import { useUser } from '../../store';
import PartnerRoute from './PartnerRoute';
import AssociationRoute from './AssociationRoute';
import ClientRoute from './ClientRoute';
import CarrierRoute from './CarrierRoute';
import loggedIn from '../../services/loggedIn';

const PrivateRoute = (props) => {
  const { user } = useUser();

  if (loggedIn(user)) {
    if (user.role === 'PARTNER') {
      return <PartnerRoute path={props.path} exact={props.exact} component={props.component} />;
    } if (user.role === 'ASSOCIATION') {
      return <AssociationRoute path={props.path} exact={props.exact} component={props.component} />;
    } if (user.role === 'CLIENT') {
      return <ClientRoute path={props.path} exact={props.exact} component={props.component} />;
    } if (user.role === 'CARRIER') {
      return <CarrierRoute path={props.path} exact={props.exact} component={props.component} />;
    }
    // logOut();
    return <Redirect to={paths.login} />;
  }
  // logOut();
  return <Redirect to={paths.login} />;
};

export default PrivateRoute;
