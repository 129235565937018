import { createTheme } from '@material-ui/core/styles'
import { ANAPATColors } from './index';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

const breakpoints = createBreakpoints({ ...customBreakpointValues })

export const themeANAPAT = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(250, 250, 250, 1)',
    },
    primary: {
      light: ANAPATColors.primary.light.hex,
      main: ANAPATColors.primary.main.hex,
      dark: ANAPATColors.primary.dark.hex,
      contrastText: ANAPATColors.primary.text.hex,
    },
    secondary: {
      light: ANAPATColors.secondary.light.hex,
      main: ANAPATColors.secondary.main.hex,
      dark: ANAPATColors.secondary.dark.hex,
      contrastText: ANAPATColors.secondary.text.hex,
    },
    error: {
      light: ANAPATColors.error.light.hex,
      main: ANAPATColors.error.main.hex,
      dark: ANAPATColors.error.dark.hex,
      contrastText: ANAPATColors.error.contrastText.hex,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiButton: {
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: ANAPATColors.secondary.main.rgbaDegradation,
        },
      },
    },
    MuiPaper: {
      root: {
        '&.MuiPaper-root': {
          [breakpoints.down('sm')]: {
            // if you want to set the md size value
            padding: '0px !important',
            backgroundColor: 'white',
            boxShadow: 'none'
          },
        },
      }
    },
    MuiTabs: {
      root: {
        paddingBottom: '15px'
      }
    }
  },
  typography: {
    fontFamily: 'MankSans, Open Sans, Arial, sans-serif',
    h1: {
      fontSize: '1.8rem',
    },
    h2: {
      fontSize: '1.6rem',
    },
    h3: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.8rem',
    },
  },
});
export default themeANAPAT;
