import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import paths from './paths';
import { useUser } from '../../store';
import loggedIn from '../../services/loggedIn';

const AssociationRoute = (props) => {
  const { path, exact, component } = props;
  const { user } = useUser();
  return loggedIn(user) && user.role === 'ASSOCIATION'
    ? (<Route path={path} exact={exact} component={component} />)
    : (<Redirect to={paths.login} />);
};

export default AssociationRoute;
